import { GridRowParams } from '@material-ui/data-grid';
import { format, getUnixTime, parseISO } from 'date-fns';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import { LojaContext } from '~/context/loja';
import api from '~/services/api';
import moment from 'moment';
import { moneyFormat } from '~/utils/functions';
import { Container } from './styles';
import { IContaPagar } from './types';
import {
  InputText,
  InputMoney,
  InputDate,
  InputTextArea,
  BuscaParceiro,
  Loja,
  ContaBanco,
  Categoria,
  Finalizadora,
} from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dataDefault from './Default/defaultData.json';
import { useQueryClient } from 'react-query';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';
import { getSchema } from './Validations';

const ContaPagar: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const { codLoja, loja } = useContext(LojaContext);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [flgDetalheEeferencia, setFlgDetalheEeferencia] =
    useState<boolean>(false);
  const [somaCondicao, setSomaCondicao] = useState(false);
  const [hasOnRowClick, setHasOnRowClick] = useState<boolean>(false);
  const [loja_id, setLoja] = useState<number>(codLoja);
  const [lojaRetriever, setLojaRetriever] = useState<number | null>(null);
  const [contaBancoRetriever, setContaBancoRetriever] = useState<number | null>(
    null,
  );
  const [inputArr, setInputArr] = useState<any[]>([]);
  const [codParcelaPagar, setCodParcelaPagar] = useState<number | null>(null);

  const queryClient = useQueryClient();
  const {
    register,
    setValue,
    control,
    watch,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema(isUpdate)),
    reValidateMode: 'onBlur',
  });

  const watchFlgQuitado = watch('flg_quitado');
  const watchFlgAceite = watch('flg_aceite');
  const watchFlgDtaQuitada = watch('flg_dta_quitada');
  const watchCodContaPagar = watch('cod_conta_pagar');

  const [formData, setFormData] = useState<IContaPagar>({
    cod_loja: {
      value: codLoja,
      isInvalid: true,
      isRequired: true,
    },
    cod_cc: {
      value: null,
      isInvalid: true,
      isRequired: true,
    },
    cod_categoria: {
      value: null,
      isInvalid: true,
      isRequired: true,
    },
    cod_finalizadora: {
      value: null,
      isInvalid: true,
      isRequired: true,
    },
    num_condicao: {
      value: 0,
      isInvalid: true,
      isRequired: true,
    },
    cod_condicao: {
      value: { label: 'DIAS DA DATA - DD', value: 2 },
      isInvalid: true,
      isRequired: true,
    },
    cod_banco_aceite: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    dta_aceite: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    des_tipo_aceite: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    cod_barra_aceite: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
  });

  const containerRef = useRef<HTMLDivElement | null>(null);

  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    setLojaRetriever(codLoja);
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
  }, []);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setLojaRetriever(null);
    setContaBancoRetriever(null);
    setInitInicializado(false);
    setFlgDetalheEeferencia(false);
    setUpdate(false);
    reset(dataDefault);
    setInputArr([]);
    setFormData({
      ...formData,
      cod_loja: {
        value: codLoja,
        isInvalid: false,
        isRequired: false,
      },
      cod_cc: {
        value: null,
        isInvalid: true,
        isRequired: true,
      },
      cod_categoria: {
        value: null,
        isInvalid: true,
        isRequired: true,
      },
      cod_finalizadora: {
        value: null,
        isInvalid: true,
        isRequired: true,
      },
      num_condicao: {
        value: 0,
        isInvalid: true,
        isRequired: true,
      },
      cod_condicao: {
        value: { label: 'DIAS DA DATA - DD', value: 2 },
        isInvalid: true,
        isRequired: true,
      },
      cod_banco_aceite: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      dta_aceite: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      des_tipo_aceite: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      cod_barra_aceite: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
    });
    setLojaRetriever(codLoja);
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
    setValue('cod_cc', {
      value: null,
      label: 'Selecione',
    });
  }, [formData, codLoja, reset, setValue, getValues]);

  const handleChangeFinalizadora = useCallback(
    (
      val: {
        label: string;
        value: number;
        cod_condicao: number;
        num_condicao: number;
      },
      isInvalid: boolean,
    ) => {
      setSomaCondicao(true);
      setFormData({
        ...formData,
        cod_finalizadora: {
          ...formData.cod_finalizadora,
          value: val,
          isInvalid,
        },
        cod_condicao: {
          ...formData.cod_condicao,
          value: { value: val.cod_condicao, label: '' },
          isInvalid,
        },
        num_condicao: {
          ...formData.num_condicao,
          value: val.num_condicao,
          isInvalid: false,
        },
      });
    },
    [formData, setValue, clearErrors],
  );

  /**
   * Validações
   */
  const validaInputsParaSubmit = useCallback((): boolean => {
    const data = formData;
    let isInvalid = false;

    if (typeof data.cod_condicao.value.value !== 'number') {
      isInvalid = true;
      data.cod_condicao.isInvalid = isInvalid;
    }

    setFormData(data);
    return isInvalid;
  }, [formData, setFormData]);

  const updateArrayInput = useCallback(
    (selected: any, nameInput: string) => {
      const itemValue = inputArr.find((i) => i.name === nameInput);

      if (itemValue) {
        itemValue.label = selected.label;
        itemValue.value = selected.value;
      } else {
        const newEntry = {
          name: nameInput,
          value: selected.value,
          label: selected.label,
        };

        setInputArr((prev) => {
          const exists = prev.some(
            (item) =>
              item.name === newEntry.name && item.value === newEntry.value,
          );

          return exists ? prev : [...prev, newEntry];
        });
      }
    },
    [inputArr],
  );

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);
    setHasOnRowClick(true);
    const {
      cod_conta_pagar,
      cod_cc,
      cod_banco,
      cod_banco_cc,
      des_cc,
      cod_loja,
      des_loja,
      des_loja_cidade,
      cod_categoria,
      descricao,
      dta_emissao,
      dta_entrada,
      val_parcela,
      num_docto,
      cod_finalizadora,
      des_finalizadora,
      num_condicao,
      cod_condicao,
      dta_vencimento,
      flg_quitado,
      dta_quitada,
      des_observacao,
      flg_aceite,
      des_tipo_aceite,
      tipo_aceite,
      cod_barra_aceite,
      cod_banco_aceite,
      dta_aceite,
      flg_detalhe_referencia,
      nome_pessoa,
      des_cidade,
      des_uf,
      cod_parcela_pagar,
    } = row;
    if (flg_aceite || flg_detalhe_referencia) {
      setValue('flg_aceite', flg_aceite);
      setFlgDetalheEeferencia(flg_detalhe_referencia);
    }
    // Preenchendo Loja
    setLojaRetriever(cod_loja);
    setValue('cod_loja', {
      value: cod_loja,
      label: `${des_loja} (${des_loja_cidade})`,
    });

    // Preenchendo Banco
    setValue('cod_cc', cod_banco_cc);
    setContaBancoRetriever(cod_cc);
    if (cod_banco_cc) updateArrayInput(cod_banco_cc, 'cod_cc');

    // Preenchendo Parceiro
    setValue('busca_parceiro', {
      label: `${row.cod_pessoa} - ${nome_pessoa} ${
        des_cidade === null || des_uf === null
          ? ''
          : ` (${des_cidade} - ${des_uf})`
      }`,
      value: row.cod_pessoa,
    });

    // Preenchendo Categoria
    setValue('cod_categoria', { value: cod_categoria, label: descricao });
    if (cod_categoria && descricao) {
      updateArrayInput(
        { value: cod_categoria, label: descricao },
        'cod_categoria',
      );
    }

    // Preenchendo Finalizadora
    setValue('cod_finalizadora', {
      value: cod_finalizadora,
      label: des_finalizadora,
    });
    if (cod_finalizadora && des_finalizadora) {
      updateArrayInput(
        {
          value: cod_finalizadora,
          label: des_finalizadora,
        },
        'cod_finalizadora',
      );
    }

    //

    // Preenchendo data emissão / data entrada / data vencimento / data quitada
    setValue(
      'dta_emissao',
      dta_emissao === null
        ? ''
        : format(createDateWithoutTimezone(dta_emissao), 'yyy-MM-dd'),
    );
    setValue(
      'dta_entrada',
      dta_entrada === null
        ? ''
        : format(createDateWithoutTimezone(dta_entrada), 'yyy-MM-dd'),
    );
    setValue(
      'dta_vencimento',
      dta_vencimento === null
        ? ''
        : format(createDateWithoutTimezone(dta_vencimento), 'yyy-MM-dd'),
    );
    setValue(
      'dta_quitada',
      dta_quitada === null
        ? ''
        : format(createDateWithoutTimezone(dta_quitada), 'yyy-MM-dd'),
    );

    // Preenchendo Valor
    setValue('val_parcela', moneyFormat(val_parcela ?? '0'));

    // Preenchendo Nº Docto
    setValue('num_docto', num_docto);

    setValue('flg_quitado', flg_quitado);
    setValue('des_observacao', des_observacao);
    setValue('cod_conta_pagar', cod_conta_pagar);

    setFormData({
      ...formData,
      cod_loja: {
        value: cod_loja,
        isInvalid: false,
        isRequired: true,
      },
      cod_cc: {
        value: {
          value: cod_cc,
          label: `${`000${cod_banco}`.slice(-3)} - ${des_cc}`,
        },
        isInvalid: false,
        isRequired: true,
      },
      cod_categoria: {
        value: {
          value: cod_categoria,
          label: descricao,
        },
        isInvalid: false,
        isRequired: true,
      },
      cod_finalizadora: {
        value: {
          value: cod_finalizadora,
          label: des_finalizadora,
        },
        isInvalid: false,
        isRequired: true,
      },
      num_condicao: {
        value: num_condicao,
        isInvalid: false,
        isRequired: true,
      },
      cod_condicao: {
        value: { label: 'DIAS DA DATA - DD', value: 2 },
        isInvalid: false,
        isRequired: true,
      },
      cod_banco_aceite: {
        value: cod_banco_aceite,
        isInvalid: false,
        isRequired: true,
      },
      dta_aceite: {
        value: dta_aceite,
        isInvalid: false,
        isRequired: true,
      },
      des_tipo_aceite: {
        value: des_tipo_aceite,
        isInvalid: false,
        isRequired: true,
      },
      cod_barra_aceite: {
        value: cod_barra_aceite,
        isInvalid: false,
        isRequired: false,
      },
    });

    setSomaCondicao(false);
    setLoader(false);
    setShowSearch(false);

    setCodParcelaPagar(cod_parcela_pagar);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
    setLojaRetriever(codLoja);
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
  };

  const formatterRequestData = (data: any) => {
    const emissao = getValues('dta_emissao');
    const vencimento = getValues('dta_vencimento');
    // calculo da diferença entre as data de emissao e vencimento
    const numCondicao = moment(vencimento).diff(moment(emissao), 'days');
    return {
      cod_loja: data.cod_loja.value,
      cod_cc: data.cod_cc.value,
      cod_pessoa: data.busca_parceiro.value,
      cod_categoria: data.cod_categoria.value,
      dta_emissao: data.dta_emissao,
      dta_entrada: data.dta_entrada,
      val_parcela: data.val_parcela,
      num_docto: data.num_docto,
      cod_finalizadora: formData.cod_finalizadora.value?.value,
      des_finalizadora: formData.cod_finalizadora.value?.label,
      num_condicao: numCondicao,
      cod_condicao: 2, // Dias da Data (Fixo)
      dta_vencimento: data.dta_vencimento,
      flg_quitado: data.flg_quitado,
      dta_quitada: data.dta_quitada,
      des_observacao: data.des_observacao,
      cod_parcela_pagar: codParcelaPagar,
    };
  };

  const onSave = handleSubmit(async (data) => {
    if (isUpdate && inputArr.length > 0) {
      inputArr.forEach((input) => {
        if ('name' in input === false) return;

        data[input.name] = { label: input.label, value: input.value };
        setValue(input.name, { label: input.label, value: input.value });
      });
    }
    await delay(800);
    if (validaInputsParaSubmit()) {
      setInitInicializado(true);
      return;
    }
    const dataEmissao = format(
      createDateWithoutTimezone(data.dta_emissao),
      'yyy-MM-dd',
    );
    const dataEntrada = format(
      createDateWithoutTimezone(data.dta_entrada),
      'yyy-MM-dd',
    );
    const dataVencimento = format(
      createDateWithoutTimezone(data.dta_vencimento),
      'yyy-MM-dd',
    );
    const dataQuitada = data.dta_quitada
      ? format(createDateWithoutTimezone(data.dta_quitada), 'yyy-MM-dd')
      : '';

    if (
      getUnixTime(parseISO(dataEntrada)) < getUnixTime(parseISO(dataEmissao))
    ) {
      setLoader(false);
      toast.warning(
        'Data de entrada deve ser maior ou igual à data de emissão',
      );
      return;
    }

    if (
      getUnixTime(parseISO(dataVencimento)) < getUnixTime(parseISO(dataEmissao))
    ) {
      setLoader(false);
      toast.warning('Vencimento deve ser maior ou igual a data de emissao');
      return;
    }
    if (
      getUnixTime(parseISO(dataQuitada)) < getUnixTime(parseISO(dataEntrada))
    ) {
      setLoader(false);
      toast.warning(
        'Data de Quitação deve ser maior ou igual à Data de Entrada',
      );
      return;
    }
    const requestData = formatterRequestData(data);
    try {
      if (isUpdate) {
        const { data: responseData } = await api.put(
          `/conta-pagar/${watchCodContaPagar}`,
          requestData,
        );

        if (responseData.success) {
          toast.success(responseData.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data: responseData } = await api.post(
        '/conta-pagar',
        requestData,
      );

      if (responseData.success) {
        toast.success(responseData.message);
        resetFormData();
      } else {
        toast.error(responseData.message);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const { data } = await api.delete(`/conta-pagar/${codParcelaPagar}`);

    if (data.success) {
      resetFormData();
      setShowSearch(true);
      setUpdate(false);
      toast.success(data.message);
    }
  };

  const handleKeyDown = (event: any) => {
    if ([69, 109, 107, 194, 110, 111].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  const onClick = async () => {
    if (watchFlgAceite) {
      return toast.warning(
        flgDetalheEeferencia
          ? 'Alteração não permitida, essa parcela é filha de um Aceite.'
          : 'Alteração não permitida, título de Aceite.',
      );
    }

    if (validaInputsParaSubmit()) {
      setInitInicializado(true);
    }

    await onSave();
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (
        containerRef.current?.contains(target) &&
        target.tagName === 'INPUT'
      ) {
        const input = target as HTMLInputElement;
        const value = getValues(input.name);

        if (!value || !value?.value) return;

        const newEntry = {
          name: input.name,
          value: value.value,
          label: value.label,
        };

        setValue(newEntry.name, {
          value: newEntry.value,
          label: newEntry.label,
        });

        setInputArr((prev) => {
          const exists = prev.some(
            (item) =>
              item.name === newEntry.name && item.value === newEntry.value,
          );

          return exists ? prev : [...prev, newEntry];
        });
      }
    };

    if (!isUpdate) return;

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [getValues, inputArr, isUpdate, setValue]);

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container ref={containerRef}>
      {showSearch && (
        <Search
          codTela={51}
          newData={() => {
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={51}
          title="Contas a Pagar"
          codigoRegistro={[
            {
              value: watchCodContaPagar,
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            const inputNames = ['cod_cc', 'cod_categoria', 'cod_finalizadora'];

            const updateInputValues = () => {
              inputNames.forEach((nome) => {
                const values = getValues(nome);
                if (!values) return;

                const input = document.querySelector<HTMLInputElement>(
                  `input[name="${nome}"]`,
                );
                if (!input) return;

                if (inputArr.length > 0) {
                  inputArr.forEach(({ name, label, value }) =>
                    setValue(name, { label, value }),
                  );
                }

                if (!values.label) setError(nome, { type: 'required' });

                if (!input.value) {
                  input.placeholder = values.label;
                  input.value = values.label;
                  setValue(nome, { label: values.label, value: values.value });
                }
              });
            };

            updateInputValues();
            await onClick();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={() => {
            if (watchFlgAceite) {
              return toast.warning(
                flgDetalheEeferencia
                  ? 'Exclusão não permitida, essa parcela é filha de um Aceite, se necessário desfaça o aceite na tela de Aceite.'
                  : 'Exclusão não permitida, título de Aceite, se necessário desfaça o aceite na tela de Aceite.',
              );
            }
            handleDelete();
          }}
          isDeactivateDeleteMessage={watchFlgAceite}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            queryClient.removeQueries('cache_bancos');
          }}
          showSwitch={false}
        >
          <div className="row">
            <div
              className={`col-sm-12 col-md-6 ${
                formData.cod_loja.value === undefined &&
                iniInicializado === true
                  ? 'loja'
                  : ''
              }`}
              style={{ zIndex: 3 }}
            >
              <Loja
                label="Loja *"
                placeholder="Selecione"
                register={register}
                isError={!!errors.cod_loja}
                control={control}
                name="cod_loja"
                changeSelected={(selected: any) => {
                  clearErrors('cod_loja');
                  setLojaRetriever(selected.cod_loja);
                  setValue('cod_loja', selected);

                  if (!hasOnRowClick) {
                    setValue('cod_cc', {
                      value: null,
                      label: 'Selecione',
                    });

                    setValue('cod_finalizadora', {
                      value: null,
                      label: 'Selecione',
                    });
                  } else {
                    setHasOnRowClick(false);
                  }
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6" style={{ zIndex: 2 }}>
              <ContaBanco
                label="Conta *"
                placeholder="Selecione"
                register={register}
                isError={!!errors.cod_cc}
                control={control}
                name="cod_cc"
                loja={lojaRetriever}
                retriever={contaBancoRetriever}
                changeSelected={(selected: any) => {
                  clearErrors('cod_cc');
                  setContaBancoRetriever(null);
                  setValue('cod_cc', selected);
                  updateArrayInput(selected, 'cod_cc');
                }}
              />
            </div>
            <div className="col-sm-12 col-md-12" style={{ zIndex: 1 }}>
              <BuscaParceiro
                label="Parceiro *"
                placeholder="Selecione..."
                name="busca_parceiro"
                disabled={watchFlgAceite}
                register={register}
                isError={!!errors.busca_parceiro}
                control={control}
                customOptions={{
                  buscarPor: { cliente: true, fornecedor: true, loja: true },
                }}
                changeSelected={(selected: any) => {
                  clearErrors('busca_parceiro');
                  const cod = selected.cod_pessoa ? selected.cod_pessoa : '';
                  const nome = selected.nome_pessoa ? selected.nome_pessoa : '';
                  const cidade = selected.des_cidade
                    ? ` (${selected.des_cidade} - ${selected.des_uf})`
                    : '';
                  setValue('busca_parceiro', {
                    value: selected.value,
                    label: `${cod} - ${nome} ${cidade}`,
                  });
                  updateArrayInput(selected, 'busca_parceiro');
                }}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <Categoria
                label="Categoria *"
                ListaWidth="560px"
                placeholder="Selecione"
                tipoCategoria="Despesa"
                register={register}
                isError={!!errors.cod_categoria}
                control={control}
                name="cod_categoria"
                changeSelected={(selected: any) => {
                  clearErrors('cod_categoria');
                  setValue('cod_categoria', selected);
                  updateArrayInput(selected, 'cod_categoria');
                }}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputDate
                register={register}
                control={control}
                name="dta_emissao"
                label="Emissão *"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event?.target;
                  clearErrors('dta_emissao');
                  setValue('dta_emissao', value);
                  setSomaCondicao(true);
                }}
                disabled={watchFlgAceite}
                isError={!!errors.dta_emissao}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputDate
                register={register}
                control={control}
                name="dta_entrada"
                label="Entrada *"
                disabled={watchFlgAceite}
                isError={!!errors.dta_entrada}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputMoney
                name="val_parcela"
                label="Valor *"
                placeholder="0,00"
                register={register}
                isError={!!errors.val_parcela}
                max={999999.01}
                min={0.0}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputText
                label="Nº Docto *"
                register={register}
                control={control}
                name="num_docto"
                disabled={watchFlgAceite}
                placeholder="Informe o Nº Docto"
                maxLength={15}
                caseInput="upper"
                isError={!!errors.num_docto}
                onInput={(ev: any) => {
                  const value = ev.target.value as string;
                  const regexCaracteresEspeciais =
                    /[!@#$^&*()_+{}[\]:;<>,.?~'-]/;
                  if (
                    regexCaracteresEspeciais.test(value) ||
                    value.toString().includes('=')
                  ) {
                    return setValue('num_docto', '');
                  }
                  setValue('num_docto', value);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <Finalizadora
                label="Finalizadora *"
                placeholder="Selecione"
                register={register}
                isError={!!errors.cod_finalizadora}
                control={control}
                name="cod_finalizadora"
                loja={lojaRetriever}
                changeSelected={(selected: any) => {
                  clearErrors('cod_finalizadora');
                  setValue('cod_finalizadora', selected);
                  handleChangeFinalizadora(
                    selected,
                    typeof selected.value !== 'number',
                  );
                  updateArrayInput(selected, 'cod_finalizadora');
                }}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputDate
                register={register}
                control={control}
                name="dta_vencimento"
                label="Vencimento *"
                disabled={watchFlgAceite}
                isError={!!errors.dta_vencimento}
              />
            </div>
            <div className="col-sm-12 col-md-1" style={{ textAlign: 'center' }}>
              <ToggleDefault
                labelText="Quitado?"
                disabled={watchFlgAceite}
                setChecked={watchFlgQuitado}
                onSwitch={() => {
                  setValue('flg_quitado', !watchFlgQuitado);
                  setValue('dta_quitada', '');
                  setValue('flg_dta_quitada', !watchFlgQuitado === false);
                }}
                // inLine
                fontSize="0.8971428571428571em"
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputDate
                register={register}
                control={control}
                name="dta_quitada"
                label="Quitação"
                disabled={watchFlgDtaQuitada || !watchFlgQuitado}
                isError={!!errors.dta_quitada}
              />
            </div>
            <div>
              <div className="row">
                <div className="col-12">
                  <Separator labelText="Aceite" />
                  <table
                    className="table table-bordered"
                    style={{
                      marginTop: '10px',
                      marginLeft: '0px',
                      color: '#6b778c',
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '10px' }}>Tipo</th>
                        <th style={{ width: '20px' }}>Data do Aceite</th>
                        <th style={{ width: '10px' }}>Banco</th>
                        <th style={{ width: '240px' }}>Captura</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{formData.des_tipo_aceite.value}</td>
                        <td>{formData.dta_aceite.value}</td>
                        <td>{formData.cod_banco_aceite.value}</td>
                        <td>{formData.cod_barra_aceite.value}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-12"
                style={{ marginTop: '10px' }}
              >
                <div>
                  <InputTextArea
                    register={register}
                    control={control}
                    disabled={watchFlgAceite}
                    name="des_observacao"
                    placeholder="Informe a Observação"
                    style={{ resize: 'none' }}
                    rows={5}
                    maxLength={150}
                  />
                </div>
              </div>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default ContaPagar;
